import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Home} from './home';
import { Credit } from './credit';
import { Bookmark, Donate, Note } from './donate';

const Stack = createNativeStackNavigator();
global.log_level = []
const linking = {
  prefixes: ['https://park.zeta2374.com', 'thusheard://'],
  config: {
    screens: {
      Home: '/:book?/:volume?/:language?',
      Credit: '/Credit',
      Bookmark: '/Bookmark',
      Donate: '/Donate',
      Note: '/Note',
      Bookmark: '/Bookmark'
    }
  },
};


export default function App() {
  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator>
        <Stack.Screen name="Home" component={Home} options={{headerShown:false}} />
        <Stack.Screen name="Note" component={Note} options={{headerShown:true}} />
        <Stack.Screen name="Credit" component={Credit} options={{headerShown:true}} />
        <Stack.Screen name='Donate' component={Donate} options={{headerShown:true}} />
        <Stack.Screen name='Bookmark' component={Bookmark} options={{headerShown:true}} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
