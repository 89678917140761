import Color from 'react-native-material-color';
import { Component, useEffect, useState } from "react";
import { StyleSheet, Platform, View, Text, TouchableOpacity, TextInput, Dimensions } from "react-native";
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5'
import { llog, min_max_height, min_max_width } from './util';
import { Popup } from './popup';
import { Input } from 'react-native-elements';
import AsyncStorage from '@react-native-async-storage/async-storage';

const found_color = 'red';
export class Ebook extends Component
{
  protected state = {selection:{start:0, end:0}, lines:100, notes:[], post:''};
  private LH = 1.3;
  private check_overlap(s1, e1, s2, e2) {
    if(s1 >= e2) return false;
    if(s2 >= e1) return false;
    return true;
  }
  underline(color, scroll, data_len) {
    if(this.state.selection.start == this.state.selection.end) return;
    for(let a of this.state.notes) if(this.check_overlap(this.state.selection.start, this.state.selection.end, a.selection.start, a.selection.end )) {
      this.refs.pop2.run('Highlight overlap is not allowed.')
      return;
    }
    let ar = this.state.notes;
    ar.push
    ( { selection:this.state.selection
      , color:color
      , note:''
      , text:this.props.text.slice(this.state.selection.start, this.state.selection.end)
      , scroll:scroll
      , data_len: data_len
      , fontsize: this.props.fontSize
      }
    );
    ar.sort((a, b)=>a.selection.start - b.selection.start)
    this.setState({notes:ar, selection:{start:0, end:0}}, this.save_note);
    llog('scroll', scroll, 3)
  }

  selection_reset = () => { this.setState({selection:{start:0, end:0}})}

  private index = 0;
  private text_render() {
    let text_array = [];
    let start = 0;
    let end = 0;
    let k = 0;
    for(let a of this.state.notes) {
      end = a.selection.start;
      if(start != end) text_array.push([this.props.text.slice(start, end)]);
      start = a.selection.end;
      text_array.push([a.text, a.color, a.note, k++])
    }
    text_array.push([this.props.text.slice(start)])
    return text_array.map((a, i)=> 
      <Text style={{backgroundColor:a.length == 1 ? undefined : a[1]}} key={i} onPress={a.length == 1 || a[1] == found_color ? undefined : ()=>{
        this.setState({post:a[2]})
        this.index = a[3]
        this.refs.pop.run()
      }}>{a[0]}</Text>);
  }

  find = (s) => {
    if(s != '') {
      let ar = this.state.notes;
      for(let i=-1; true;) {
        i = this.props.text.indexOf(s, i+1);
        if(i == -1) break;
        ar.push
        ( { selection:{start:i, end:i+s.length}
          , color:found_color
          , note:''
          , text:s
          , scroll:0
          , data_len:7
          , fontsize: this.props.fontSize
          }
        );
      }
      ar.sort((a, b)=>a.selection.start - b.selection.start)
      this.setState({notes:ar});
    }
  }
  find_clear = () => {
    this.setState({notes:notes.filter((a)=>a.color!=found_color)})
  }

  private convention;
  private save_note = () => {
    if(this.state.notes.length == 0) AsyncStorage.removeItem('note_' + this.props.convention);
    else AsyncStorage.setItem('note_' + this.props.convention, JSON.stringify({note:this.state.notes.filter((a)=>a.color!=found_color), stack:this.props.stack}));
  }
  private get_note = async () => {
    this.convention = this.props.convention;
    let r = await AsyncStorage.getItem('note_'+ this.props.convention);
    if(r != null) this.setState({notes:JSON.parse(r)['note']})
    else this.setState({notes:[]})
  }
  componentDidMount() {
    this.get_note();
  }

  shouldComponentUpdate() {
    if(this.props.convention != this.convention) this.get_note()
    return true;
  }

  private on_note_popup = (i) => {
    if(i == 0) return;
    else if(i == 2) this.state.notes[this.index].note = this.state.post; 
    else if(i == 1) this.state.notes.splice(this.index, 1);
    this.setState({notes:this.state.notes}, this.save_note);
  }

  render() {
    return (
    <View style={{paddingHorizontal:15}}>
      { this.props.mode 
      ? <Text style={{fontSize:this.props.fontSize, fontFamily:Platform.OS != 'ios' ? 'serif' : undefined, lineHeight:this.props.fontSize * this.LH }} 
          onTextLayout={(e)=>this.setState({lines:e.nativeEvent.lines.length})} // not called in web
          onLayout={(e)=>{if(Platform.OS == 'web') this.setState({lines:e.nativeEvent.layout.height /(this.LH * this.props.fontSize)})}} 
        >
          {this.text_render()}
        </Text>
      : <TextInput ref='input' editable={Platform.OS == 'android' ? true : false} value={this.props.text} 
          onSelectionChange={({nativeEvent:{selection}})=>{this.setState({selection:selection}); llog('selection', selection, 1);}} scrollEnabled={false} 
          onPressIn={Platform.OS == 'android' ? ()=>this.props.scrollfunc() : null}
          numberOfLines={this.state.lines} multiline={true} showSoftInputOnFocus={Platform.OS == 'android' ? false : true}
          onLayout={()=>{if(Platform.OS != 'web') this.props.scrollfunc();}}
          style={{fontSize:this.props.fontSize, fontFamily:Platform.OS != 'ios' ? 'serif' : undefined, flex:1, lineHeight:this.props.fontSize*this.LH}} scrollEnabled={false} 
        />
      }
      <Popup ref='pop' buttons={['Cancel', 'Delete', 'Save']} func={this.on_note_popup} >
        <Input numberOfLines={5} placeholder='Insert Note' value={this.state.post} multiline={true} onChangeText={(s)=>this.setState({post:s})} 
          inputContainerStyle={{width:min_max_width([0.5], [0.6], 0), height:min_max_height([0.3, 100], [0.6], 0)}} 
        />
      </Popup>
      <Popup ref='pop2' buttons={['확인']} func={(i)=>{}} />
    </View>
    )
  }
}

export function Highlighter(props) { // this component should be paired with Ebook
  return (
    <View style={[styles.box, props.style]}>
    { !props.mode && ['yellow', '#f9f9c8', '#9cdef8', '#aee3d4', '#f6bdcd', '#f8ca8f'].map
      ( (a, i)=> 
        <TouchableOpacity style={[styles.colors, {backgroundColor:a}]} key={i} onPress={()=>{
          props.underlinefunc(a);
          props.modefunc();
        } } />
      )
    }
    <TouchableOpacity style={styles.highlighter} onPress={()=>props.modefunc()} >
      <FontAwesome5 name='highlighter' size={30} color='#fff' />
    </TouchableOpacity>
    </View>
  );
}


const styles = StyleSheet.create
( { box:
    { alignItems:'center'
    , 
    }
  , highlighter:
    { backgroundColor:Color.ORANGE[900]
    , borderRadius:25
    , width:50
    , height:50
    , alignItems:'center'
    , justifyContent:'center'
    }
  , colors:
    { width: 30
    , height: 30
    , borderRadius: 15
    , marginVertical: 10
    }
  }
);