import { useEffect, useState } from "react";
import { ScrollView, StyleSheet, Platform, View, Text, TouchableOpacity } from "react-native";
import WebView from "react-native-webview";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Stack_View } from "./home";
import { fetch2, llog } from "./util";
import AntDesign from 'react-native-vector-icons/AntDesign'
import Color from 'react-native-material-color';

const cloneDeep = require('lodash.clonedeep');

export function Donate() {
  if (Platform.OS == 'web') return <iframe src={'https://biz.zeta2374.com:5500/paypal.html'} width='100%' height={'100%'} sandbox="allow-same-origin allow-scripts allow-popups allow-forms" />
  else return <WebView nestedScrollEnabled source={{ uri: 'https://biz.zeta2374.com:5500/paypal.html' }} height={'100%'} />
}

const stack2book = (st) => {
  let book ='';
  let volume = 0;
  llog('stack', st, 3)
  if(st[1].name == 'Nikaya') {
    book = st[st.length - 2]['name'];
    book = /(\D+)\d*/g.exec(book)[1];
    volume = st[st.length - 1]['name'].split('_')[0].slice(book.length);
  } else {
    book = st[st.length - 2]['name'].split(' ')[0];
    let tmp = st[st.length - 1]['title'].split(' ');
    volume = parseInt(/第(\d+)卷/g.exec(tmp[tmp.length - 1])[1]);
  }
  llog(book, volume, 1)
  return {book: book, volume:volume};
}

const sort_func = (c, d) => {
  for(let i=0; i<Math.max(c[1].length, d[1].length); i++) {
    if(i > c[1].length) return -1;
    if(i > d[1].length) return 1;
    let a = ('name' in c[1][i] ? c[1][i].name : c[1][i].title);
    let b = ('name' in d[1][i] ? d[1][i].name : d[1][i].title);
    if(a == b) continue;
    return a.localeCompare(b);
  }
  return c[0].localeCompare(d[0])
}

const sort_func2 = (e, f) => {
  let c = JSON.parse(e[1]).stack;
  let d = JSON.parse(f[1]).stack;
  return sort_func([e[0], c], [f[0], d])
}

export function Bookmark({navigation}) {
  const [bookmarks, setBookmarks] = useState([]);
  const [saved, setSaved] = useState([]);// local saved

  useEffect(()=>llog('data bookmark', bookmarks, 3), [bookmarks])
  useEffect(()=>llog('data bookmark', saved, 3), [saved])
  useEffect
  ( ()=>
    { AsyncStorage.getAllKeys()
      . then
        ( (keys)=>
          { AsyncStorage.multiGet(keys.filter((a)=>a.startsWith('bookmark_')))
            . then ( (d)=> { setBookmarks(d.map((a)=> [a[0].slice('bookmark_'.length), JSON.parse(a[1])]).sort(sort_func)) }) ;
            setSaved(keys.filter((a)=>a.startsWith('data_') ))
          }
        )
      ;
    }
  , []
  );
  const save = (id) => {
    llog('id', id, 4)
    fetch2(id).then((r)=>{
      AsyncStorage.setItem( 'data_' + id, JSON.stringify(r));
      setSaved([...saved, 'data_' + id]);
    })
  }
  const del_data = (id) => {
    AsyncStorage.removeItem('data_' + id);
    setSaved(saved.filter((a)=>a != 'data_' + id));
  }
  return (
    <ScrollView style={{backgroundColor:global.color, paddingHorizontal:5}}>
      <Text style={{fontSize:12, margin:10, color:'#555'}}>You can add or remove a bookmark in Home screen by pressing the last title button when you are seeing the Sutra text.</Text>
      {bookmarks.map((a, i)=> {
        let options = [];
        let st = cloneDeep(a[1]);
        let key = cloneDeep(a[0]);
        options[i] = stack2book(a[1]);
        if(key.startsWith('deepl')) options[i].language = 'en';
        st.splice(0, 1);
        if(key.startsWith('nikaya')) {
          let front = key.split('/')[1].split('_')[1];
          if(front.startsWith('root')) st.push({name:'pli'});
          else {
            let lang = front.slice(12);
            st.push({name:lang});//translation-
            options[i].language = lang;
          }
        } else st.push({name:key.split('/')[0]});
        llog('st', st, 4)
        //st.sort(sort_func);
        return (
        <View style={{flexDirection:'row', alignItems:'center', flexWrap:'wrap', marginBottom:10}} key={i} >
          <Stack_View stack={st} func={()=>navigation.replace('Home', options[i])} >
            <View style={styles.font_button} >
              { saved.includes('data_' + key)
              ? <AntDesign name='delete' size={30} color='#fff' onPress={()=>del_data(key)} />
              : <AntDesign name='clouddownload' size={30} color='#fff' onPress={()=>save(key)} />
              }
            </View>
            <View style={{width:20}} />
          </Stack_View>
        </View>
        )}
      )}
    </ScrollView>
  );
}

export function Note({navigation}) {
  const [data, setData] = useState({});
  useEffect(()=>llog('data', data, 3), [data]);
  useEffect
  ( ()=>
    { AsyncStorage.getAllKeys()
      . then
        ( (keys)=>
          { AsyncStorage.multiGet(keys.filter((a)=>a.startsWith('note_') ))
            . then 
              ( (d)=> 
                { let obj = {};
                  llog('d', d, 4)
                  d.sort(sort_func2)
                  llog('d', d, 4)
                  for(let a of d) obj[a[0].slice('note_'.length)] = JSON.parse(a[1]);
                  setData(obj);
                }
              )
          }
        )
      ;
    }
  , []
  );
  return (
    <ScrollView style={{backgroundColor:global.color}}>
    { Object.keys(data).map
      ( (a, i)=>
        { let options = [];
          let st = cloneDeep(data[a].stack);
          let key = cloneDeep(a);
          options[i] = stack2book(data[a].stack);
          if(key.startsWith('deepl')) options[i].language = 'en';
          st.splice(0, 1);
          if(key.startsWith('nikaya')) {
            let front = key.split('/')[1].split('_')[1];
            if(front.startsWith('root')) st.push({name:'pli'});
            else {
              let lang = front.slice(12);
              st.push({name:lang});//translation-
              options[i].language = lang;
            }
          } else st.push({name:key.split('/')[0]});

          return ( 
          <View key={i}>
            <Stack_View stack={st} func={()=>navigation.replace('Home', options[i])} />
            { data[a].note.map
              ( (b, j)=> 
                { let {book, volume, language} = options[i];
                  return (
                  <TouchableOpacity key={j} style={{flexDirection:'row', padding:5, paddingLeft:20}} 
                    onPress={()=>navigation.replace('Home', {book:book, volume:volume, language:language, scroll:b.scroll, fontsize:b.fontsize})} 
                  >
                    <Text>
                      <Text style={{backgroundColor:b.color, fontSize:20, lineHeight:30 }}>{b.text}</Text>
                      <Text style={{fontSize:20, lineHeight:30, marginLeft:30}} > {b.note}</Text>
                    </Text>
                  </TouchableOpacity>
                  )
                }
              )
            }
          </View>
          )
        }
      )
    }
    </ScrollView>
  )
}

const styles = StyleSheet.create
( { font_button: 
    { alignItems: 'center'
    , justifyContent: 'center'
    , backgroundColor: Color.ORANGE[900]
    , width: 50
    , height: 40
    , borderRadius: 15
    , borderWidth: 1
    }
  }
);