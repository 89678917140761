import { FlatList, Text, Platform, ScrollView, StyleSheet, TouchableOpacity, View, Dimensions } from 'react-native';
import {  useEffect, useRef, useState } from 'react';
import { Image } from 'react-native-elements';
import { fetch2, llog, min_max_height, min_max_width } from './util';
import { Button, Input } from 'react-native-elements';
import Color from 'react-native-material-color';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Popup } from './popup';
import { SafeAreaView } from 'react-native-safe-area-context';
import Entypo from 'react-native-vector-icons/Entypo'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import Ionicons from 'react-native-vector-icons/Ionicons'
import AntDesign from 'react-native-vector-icons/AntDesign'
import { Ebook, Highlighter } from './ebook';

const cbeta_index = require('./assets/cbeta.json');
const nikaya_index = require('./assets/nikaya.json');
const parallel = require('./assets/parallels.json').filter((a)=>'parallels' in a).map((a)=>a['parallels']) //remove mention
.map((a)=>{return a.filter((b)=>!b.startsWith('~'))}) // remove similar
.filter((a)=>a.length != 0) // remove empty
.map((a)=>{return a.map((b)=>b.split('#')[0])}) //remove detail line
//.map((a)=>{return a.map((b)=>{
//  if(b.startsWith('ea') && !b.startsWith('ea-')) return b.split('.')[0];
//  else return b;
//})})//remove .4 from ea35.4
const agama_volume = require('./assets/agama.json');//권 = da[절-1]

export function Stack_View(props) {
  return (
    <View style={{flexDirection:'row', flexWrap:'wrap'}} >
      { props.children }
      { props.stack.map((a, i)=> {
        return (
          <Button title={a.name ? a.name : a.title} onPress={()=>{props.func(i)}} key={i} titleStyle={{fontWeight:'700'}} titleProps={{allowFontScaling:false}}
            buttonStyle={{borderBottomRightRadius:20, height:40, borderTopRightRadius:20, backgroundColor:Color.ORANGE[900 - 0*100], borderWidth:1, borderColor:'#000'}}
          />
        )
      })}
    </View>
  )
}

let found_stack = []
const recur_iter = (json, to_find, stack) => {
  if(Array.isArray(json)) {
    if(json.includes(to_find)) found_stack = stack
  } else {
    if(to_find in json) found_stack = stack
    else for(let i in json) recur_iter(json[i], to_find, [...stack, i])
  }
}
const is_digit = (c) => {
  return c >= '0' && c<= '9';
}
const subsection213 = (si) => {
  let section_start = [1, 12, 24, 30]
  for (let i = 0; i < section_start.length-1; i++) if(parseInt(si) < section_start[i+1]) return i+1;
  return 4;
}
const subsection211 = (si) => {
  let section_start = [1, 2, 19, 32]
  for (let i = 0; i < section_start.length-1; i++) if(parseInt(si) < section_start[i+1]) return i+1;
  return 4;
}
const count_num = (s) => {
  let sum = 0;
  for(let a of s) if(is_digit(a)) sum++;
  return sum;
}

const book_check = (s) => {
  const [a, book, num] = /(\D+)(.*)/g.exec(s)
  return [book, num, book[0] == book[0].toUpperCase()]
}
const check_book_exist = (s) => {
  s = /(\D+).*/g.exec(s)[1];
  let agama = ['da', 'ma', 'sa', 'ea', 't'];
  if(agama.includes(s)) return true;
  found_stack = [];
  recur_iter(nikaya_index, s, []);
  return found_stack.length != 0;
}

const book_search = (s) => {
  let ret = [];
  for(let i of cbeta_index['collections']) for(let j of cbeta_index[i['uuid']]) 
    if(j.name.split(' ')[1].includes(s)) ret.push(j.name);
  return ret;//[ "T0001 長阿含經", "T0013 長阿含十報法經" ]
}

const transform_to_name = (a) =>{
  if(Array.isArray(a)) return a.map((a)=>{return {name:a}});
  else return Object.keys(a).map((a)=>{return {name:a}});
}
const vol2abbr = (book, volume) => { // T0001, 1 -> [da1]
  const taisho = ['T0001', 'T0026', 'T0099', 'T0125'];
  let agama = ['da', 'ma', 'sa', 'ea'];
  let idx = taisho.indexOf(book);
  if(idx != -1) {
    book = agama[idx];
    return agama_volume[book].map
    ( (a, i)=> 
      { if(a==parseInt(volume)) return i+1; 
        else return -i;
      }
    )
    . filter((a)=>a>0)
    . map((a)=>book + a);
  } else if(book.startsWith('T') && !book.startsWith('TX')) return ['t' + /\D+0*(.+)/g.exec(book)[1] + '.' + volume];
  else if(book[0] == book[0].toUpperCase()) return []; // no matching abbrev for other than taisho cbeta 
  else {
    book = /(\D+)\d*/g.exec(book)[1]// in case sn8, an7
    return [book + volume]
  } 
}
const abbr2vol = (abbr) => { // da2 -> ['T0001', '4']
  let [a, book, vol] = /(\D+)(.+)/g.exec(abbr);
  const agama = ['da', 'ma', 'sa', 'ea'];
  const taisho = ['T0001', 'T0026', 'T0099', 'T0125'];
  //if(book[0] == '~') book = book.slice(1)
  let idx = agama.indexOf(book);
  if(idx != -1) {
    vol = vol.split('.')[0];
    vol = agama_volume[book][parseInt(vol) -1];// Number
    book = taisho[idx];
  } else if(book == 't') {
    book = 'T';
    let [a, b] = vol.split('.');
    while(count_num(a) < 4) a = '0' + a;
    book += a;
    if(b === undefined) vol = 1;
    else if(book == 'T0213') vol = subsection213(b)
    else if(book == 'T0211') vol = subsection211(b)
    else vol = parseInt(b);
  } else if(book == 'dhp') vol = to_dhp_volume(vol);
  return [book, vol];
}

const check_include = (ar, els) => { // da2.1 == da2 t231.1 || t213.1 or t213
  for(let el of els) if(ar.includes(el)) return true;
  for(let el of els) {
    let [a, b] = el.split('.');
    if(b == '1' && ar.includes(a)) return true;
    if(a.startsWith('ea')) for(let c of ar) if(c.startsWith(a)) return true;
  }
  return false;
}
const find_parallels = (ar) => { //[da14] => [dn1, sn1.2]
  if(ar.length == 0) return [];
  let par = parallel.filter((a)=>check_include(a, ar));
  let s = new Set();
  for(let a of par) for(let b of a) if(check_book_exist(b)) s.add(b); // remove detail line info
  for(let a of ar) s.delete(a);
  return Array.from(s);
}
const to_dhp_volume = (si) => {
  if(si.includes('-')) return si;
  let vol_range = nikaya_index['sutta']['kn']['dhp'].map((a)=>/dhp(.+)_root-pli-ms/g.exec(a)[1])
  .map((a)=>a.split('-'))
  .map((a)=>{return a.map((b)=>parseInt(b))});
  let vol = parseInt(si);
  for(let a of vol_range) if(vol >= a[0] && vol <= a[1]) return a[0] + '-' + a[1];
  return ''
}




export function Home({route, navigation}) {
  const [data, setData] = useState([[{name:'漢文大藏經', uuid:'collections'}, {name:'Nikaya'}]]);
  const [extra, setExtra] = useState(0);
  const [stack, setStack] = useState([{name:'如是我聞'}]);
  const [fontSize, setFontSize] = useState(20);
  const [color, setColor] = useState('#fff');
  const [searched, setSearched] = useState([]);
  const [tosearch, setTosearch] = useState('');
  const [mode, setMode] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [loading, setLoading] = useState(false);
  const pop = useRef();
  const search = useRef();
  const high = useRef();
  const flat = useRef();
  
  const saveColor = (c) => {
    setColor(c);
    global.color = c;
    AsyncStorage.setItem('color', c);
  }
  const saveFontSize = (sz) => {
    setFontSize(sz);
    global.fontsize = sz;
    AsyncStorage.setItem('fontsize', sz.toString());
  }

  const render_parallel = () => {
    if(data.length == 0 || data[data.length-1].length ==0 || !is_sutta_text()) return null;
    let book = route.params.book;
    let volume = route.params.volume;
    let abbr = vol2abbr(book, volume);
    let par = find_parallels(abbr);
    if(par.length == 0) return null;
    return par.map( (a, i)=>{
      const [b, v] = abbr2vol(a);
      return (
        <TouchableOpacity key={i} style={styles.font_button} onPress={()=>navigation.replace('Home', {book:b, volume:v})} >
          {false && <Entypo name='flow-parallel' size={25} color='#fff' />}
          <Text style={{color:'#fff', allowFontScaling:false}}>{a}</Text>
        </TouchableOpacity>
      )
    })
  }

  const one_second_later = (s) => {
    setTimeout(()=>{
      if(high.current === undefined) one_second_later(s);
      else high.current.find(s)
    }, 1000)
  }
  const one_second_later2 = (k, fontsize) => {
    setTimeout(()=>{
      if(flat.current === undefined) one_second_later2(k, fontsize);
      else {
        flat.current.scrollToOffset({offset:k, animated:true});
        if(fontsize !== undefined) setFontSize(fontsize)
      }
    }, 1000)
  }

  useEffect(()=>{//for url access
    //AsyncStorage.clear();
    AsyncStorage.getItem('color').then((r)=>{ if(r != null) {setColor(r); global.color = r;} });
    AsyncStorage.getItem('fontsize').then((r)=>{ if(r != null) {setFontSize(parseInt(r)); global.fontsize = r;} });
    if(route.params === undefined || route.params.book === undefined) return ()=>{};
    //if(route.params.fontsize !== undefined) setTimeout(() => { setFontSize(route.params.fontsize); }, 1000); 
    if(route.params.scroll !== undefined) one_second_later2(route.params.scroll, route.params.fontsize);
    if(route.params.tofind !== undefined) one_second_later(route.params.tofind);
    const [book, num, is_agama] = book_check(route.params.book);
    let volume = route.params.volume;
    let language = route.params.language;
    let add_stack = [...stack];
    let add_data = [...data];
    if(is_agama) {
      add_stack.push({ name: '漢文大藏經', uuid: 'collection' });
      add_data.push(cbeta_index['collections']);
      for(let a of cbeta_index['collections']) {
        if(a.name.startsWith(book + ' ')) {
          add_stack.push(a);
          add_data.push(cbeta_index[a.uuid]);
          for(let b of cbeta_index[a.uuid]) {
            if(b.name.startsWith(route.params.book + ' ')) {
              add_stack.push(b);
              add_data.push(cbeta_index[b.uuid]);
              if(volume === undefined) {
                setStack(add_stack);
                setData(add_data);
              } else for(let c of cbeta_index[b.uuid]) {
                if(c.title.endsWith(volume + '卷')) {
                  setStack([...add_stack, c]);
                  fetch2('cbeta/' + c.uuid).then((r)=>{
                    if(language !== undefined) fetch2('deepl/' + c.uuid).then((r2)=> {
                      setData([...add_data, r.data, [{contents:r2.data.map((d)=>d.source_sentence + '\n' + d.translation + '\n').join('\n'), uuid:c.uuid}]])
                    })
                    else setData([...add_data, r.data])
                  });
                  break;
                }
              }
              break;
            }
          }
          break;
        }
      }
    } else {
      add_stack.push({name:'Nikaya'})
      found_stack = []
      let to_find = book + volume + '_root-pli-ms';
      if(volume === undefined) to_find = book;
      recur_iter(nikaya_index, to_find, [])
      if(found_stack.length == 0) return ()=>{};
      setStack([...add_stack, ...transform_to_name(found_stack), {name:to_find}]);
      let st = nikaya_index;
      for(let a of found_stack) {
        add_data.push(transform_to_name(st));
        st = st[a];
      }
      add_data.push(transform_to_name(st));
      if(volume === undefined) setData([...add_data, transform_to_name(st[book])]);
      else
      { fetch2('nikaya/' + book + volume + '_root-pli-ms')
        . then
          ( (r)=>
            { if(language !== undefined) 
              { let url = 'nikaya/' + book + volume + '_translation-' + language;
                fetch2(url).then
                ( (r2)=>
                  { setData
                    ( [ ...add_data
                      , [{contents:r.data, translation:r.translation.rows, html_translation:r.html_translation.rows}]
                      , [{contents:r2.data, translation:r2.translation.rows, html_translation:r2.html_translation.rows, uuid:r2._id}]
                      ]
                    );
                  }
                );
              }
              else setData([...add_data, [{contents:r.data, translation:r.translation.rows, html_translation:r.html_translation.rows}]]);
            }
          )
        ;
      } 
    }
  }, [])
  const get_data = async (item) => { // called on text level navigation
    if('uuid' in item) {//chinese
      if(item.uuid in cbeta_index) {
        setData([...data, cbeta_index[item.uuid]])
        if(stack.length == 3) navigation.setParams({book:item.name.split(' ')[0]})
      } else {
        let r = await fetch2('cbeta/' + item.uuid);
        if('_id' in r) { setData([...data, r.data])} 
        navigation.setParams({volume:/第(\d+)卷/g.exec(item.title)[1]})
      }
    } else {//nikaya
      if(item.name.endsWith('_root-pli-ms')) {
        let r = await fetch2('nikaya/' + item.name);
        llog('r', r, 4)
        process_fetched_data(r)
        navigation.setParams({volume:/\D+(.+)_root-pli-ms/g.exec(item.name)[1]})
      } else {
        let st = nikaya_index;
        for(let i=2; i<stack.length; i++) st = st[stack[i]['name']];
        if(item.name != 'Nikaya') st = st[item.name]
        setData([...data, transform_to_name(st)])
        if(Array.isArray(st) || item.name == 'sn' || item.name == 'an' || item.name == 'kn') navigation.setParams({book:item.name})
      }
    }
    await setStack([...stack, item])
    setExtra(extra + 1)
  }
  const get_convention_key = () => {
    let is_nikaya = stack[1].name == 'Nikaya';
    let key = '';
    if (is_nikaya) key += 'nikaya/';
    else key += (stack.length < data.length) ? 'deepl/' : 'cbeta/';
    if (is_nikaya) {
      if (stack.length < data.length) key += data[data.length - 1][0].uuid
      else key += stack[stack.length-1][is_nikaya ? 'name' : 'uuid'];
    } else key += stack[stack.length-1]['uuid'];
    return key;
  }
  const pop_stack = async (i) => { // called when Stack_View is clicked
    if('contents' in data[i][0]) { //bookmark
      let convention = get_convention_key();
      let key = 'bookmark_' + convention;
      if(await AsyncStorage.getItem(key) == null) {
        AsyncStorage.setItem(key, JSON.stringify(stack))
        pop.current.run('Bookmark Added.')
      } else {
        let r = await AsyncStorage.getItem('data_' + convention);
        if(r != null) pop.current.run('Bookmark deletions failed. Please remove downloaded text first.')
        else {
          AsyncStorage.removeItem(key)
          pop.current.run('Bookmark Removed.')
        }
      }
    } else {
      stack.splice(i+1)
      data.splice(i+1)
      setStack(stack)
      setData(data)
      setExtra(extra + 1)
    }
  }
  const calc_number = (s) => {
    let sum:number = 0;
    let num = '';
    let multiply = 10000 * 10000 * 10000 * 10000;
    for(let a of s) {
      if(is_digit(a)) num += a;
      else if(num == '') continue;
      else {
        sum += multiply * parseInt(num);
        multiply /= 10000;
        num = '';
      }
    }
    if(num != '') sum += multiply * parseInt(num);
    return sum;
  }
  const extract_number = (s) => {
    let r = '';
    let set = false;
    for(let a of s) {
      if(set) r += a;
      if(a == ':' || a == '.') set = true;
    }
    return r;
  }
  const remove8line = (s:string, line = 8) => {
    let idx = 0;
    for(let i=0; i<line; i++) idx = s.indexOf('\n', idx + 1);
    return s.slice(idx);
  }
  const contents_process = (item) => {
    if(item.contents.constructor == Object) return Object.keys(item.contents)
    . sort((a,b)=>calc_number(a) - calc_number(b))
    . map((a, i)=>item.contents[a])
    . join('\n')
    else if(item.contents.startsWith('<!DOCTYPE')) return remove8line(item.contents.replace(/<[^>]+>/g, ''), 10);
    else return remove8line(item.contents);
  }
  const renderItem = ({item, index}) => {
    llog('item', item.contents, 1)
    if('contents' in item) {
      return (
        <Ebook ref={high} fontSize={fontSize} text={contents_process(item)} 
          mode={mode} scrollfunc={()=>flat.current.scrollToOffset({offset:scrollPosition, animated:false})} 
          stack={stack} convention={get_convention_key()} 
        />
      ) 
    } else return (//titles
      <TouchableOpacity onPress={()=>get_data(item)} style={{borderBottomWidth:1, borderColor:'#888'}} >
        <Text style={{fontSize:fontSize, fontFamily:Platform.OS != 'ios' ? 'serif' : undefined, lineHeight:fontSize*1.9, fontWeight:'500'}} 
          selectable={true} >
          {item.name ? item.name : item.title} {item.resourceCount}
        </Text>
      </TouchableOpacity>
    )
  }

  const process_fetched_data = (r) => {
    //let text = Object.keys(r.data).map((a) => [a.split(':')[1], r.data[a]]);
    setData([...data, [{ contents: r.data, translation:r.translation.rows, html_translation:r.html_translation.rows, uuid:r._id }]]);
    setExtra(extra + 1);
  }

  const render_flags = () => {//for nikaya
    if(stack.length < 2 || stack[1].name != 'Nikaya') return null;
    if(!is_sutta_text()) return null;
    let dict = {};
    for(let a of data[data.length-1][0].translation) {
      let length = a.id.split('_')[0].length + '_translation-'.length;
      let language = a.id.slice(length, length + 2);
      dict[language] = a.id;
    }
    llog('html', data[data.length-1][0].html_translation, 4);
    for(let a of data[data.length-1][0].html_translation) {
      let length = a.id.split('_')[0].length + '_translation-'.length;
      let language = a.id.slice(length, length + 2);
      if(!(language in dict)) dict[language] = a.id;
    }
    llog('dict', dict, 5)
    return ['en', 'de', 'es', 'fr', 'fi', 'my', 'hi', 'gu', 'ca', 'id', 'cs','jp', 'it', 'pl', 'lt', 'ru', 'pt', 'si', 'sr', 'vi', 'zh', 'no', 'ta', 'th', 'ko', 'bn', 'hu', 'sl']
    .map((a, i)=>{
      if(Object.keys(dict).includes(a)) return <Image source={flag_image[i]} style={{height:40}} key={i} containerStyle={styles.flags} onPress={()=> {fetch2('nikaya/' + dict[a]).then(process_fetched_data) } } />
      else return null;
    });
  }

  const deep_translation = () => {
    let is_taisho = stack[2].name.startsWith('T ') || stack[2].name.startsWith('X ');
    if(data.length == 5) fetch2((is_taisho ? 'deepl/' : 'translate/') + stack[4]['uuid'])
    . then((r)=>{
      setData([...data, [{contents:r.data.map((a, i)=>(is_taisho ? a.source_sentence : a.source) + '\n' + a.translation + '\n').join('\n'), uuid: stack[4]['uuid']}]])
      setExtra(extra+1)
    })
  }

  const is_sutta_text = () => {
    return 'contents' in data[data.length - 1][0];
  }

  const text_search = () => {
    setLoading(true); 
    fetch2('search/' + tosearch).then
    ( (r) => 
      { setSearched(r.results.map((a, i) => a.work + ' ' + a.title + ' 第' + a.juan + '卷 ' + a.term_hits + '回'));
        setLoading(false);
      } 
    );
  }

  const to_option = (s) => {
    let options = {};
    options.book = s.split(' ')[0];
    let e = /第(\d+)卷/g.exec(s);
    if(e != null) {
      options.volume = e[1];
      options.tofind = tosearch;
    }
    return options;
  }
  const have_page = (is_prev:Boolean) => { // check for < > button
    if(!is_sutta_text()) return false;
    let name = 'title';
    if('name' in stack[stack.length - 1]) name = 'name';
    return data[stack.length - 2][is_prev ? 0 : (data[stack.length - 2].length - 1)][name] != stack[stack.length - 1][name]
  }
  const prev_next_page = (is_prev:Boolean) => { // navigate juans or mn1 <-> mn2
    let name = 'title';
    if('name' in stack[stack.length - 1]) name = 'name';
    let mapped = data[stack.length - 2].map((a)=>a[name]);
    let idx = mapped.indexOf(stack[stack.length - 1][name]);
    idx = is_prev ? idx -1 : idx +1;
    let vol = '';
    if(name == 'name') vol = /\D+(.+)/g.exec(mapped[idx].split('_')[0])[1];
    else vol = /第(\d+)卷/g.exec(mapped[idx])[1];
    navigation.replace('Home', {book:route.params.book, volume:vol})
  }


  return (
    <SafeAreaView style={{flex:1, padding:10, backgroundColor:color}}>
    <FlatList data={data[data.length - 1]} extraData={extra} renderItem={renderItem} ref={flat}
      onScrollEndDrag={(e)=>{ llog('scroll end', e.nativeEvent.contentOffset.y, 1); setScrollPosition(e.nativeEvent.contentOffset.y); }}
      removeClippedSubviews={false}
      ListHeaderComponent={ 
        <View style={{marginBottom:10}} >
          <Stack_View stack={stack} func={pop_stack} /> 
          <View style={{flexDirection:'row', flexWrap:'wrap'}} >
            {false && <TouchableOpacity onPress={()=>AsyncStorage.clear()} style={styles.font_button} >
              <Ionicons name='nuclear' size={25} color='#fff' />
            </TouchableOpacity> }
            <TouchableOpacity onPress={()=>navigation.navigate('Credit')} style={styles.font_button} >
              <Entypo name='info-with-circle' size={25} color='#fff' />
            </TouchableOpacity>
            <TouchableOpacity onPress={()=>navigation.navigate('Bookmark')} style={styles.font_button} >
              <Entypo name='bookmark' size={25} color='#fff' />
            </TouchableOpacity>
            <TouchableOpacity onPress={()=>navigation.navigate('Note')} style={styles.font_button} >
              <MaterialCommunityIcons name='note-text-outline' color='#fff' size={25} />
            </TouchableOpacity>
            <TouchableOpacity onPress={()=>search.current.run()} style={styles.font_button} >
              <FontAwesome name='search' size={25} color='#fff' />
            </TouchableOpacity>
            <TouchableOpacity onPress={()=>saveFontSize(fontSize - 1)} style={styles.font_button} >
              <MaterialCommunityIcons name='format-font-size-decrease' size={25} color='#fff' />
            </TouchableOpacity>
            <TouchableOpacity onPress={()=>saveFontSize(fontSize + 1)} style={styles.font_button} >
              <MaterialCommunityIcons name='format-font-size-increase' size={25} color='#fff' />
            </TouchableOpacity>
            { have_page(true) &&
              <TouchableOpacity onPress={()=>prev_next_page(true)} style={styles.font_button} >
                <AntDesign name='caretleft' size={25} color='#fff' />
              </TouchableOpacity>
            }
            { have_page(false) &&
              <TouchableOpacity onPress={()=>prev_next_page(false)} style={styles.font_button} >
                <AntDesign name='caretright' size={25} color='#fff' />
              </TouchableOpacity>
            }
            { ['#fff', '#f5f5dc', '#edd1b0','#c5c5c5', '#e8ebe6', '#f5e8aa', '#f4f0e8'].map((a, i)=>
              <TouchableOpacity style={[styles.font_button, {backgroundColor:a}]} key={i} onPress={()=>saveColor(a)} />
            ) }
            { data.length > 0 && data[data.length - 1].length > 0 && is_sutta_text() && 
              <Image source={require('./assets/wheel.png')} containerStyle={styles.flags} onPress={()=>{
                data.splice(stack.length);
                setData(data);
                setExtra(extra+1);
              }} />
            }
            { data.length > 0 && is_sutta_text() && stack[1]['name'] == '漢文大藏經' && (stack[2].name.startsWith('T ') || stack[2].name.startsWith('X ')) &&
                <Image source={require('./assets/en.png')} containerStyle={styles.flags} onPress={deep_translation} />
            }
            { render_flags() }
            { render_parallel() }
            <View style={{height:40}} />
          </View>
        </View>
      } 
    />
    <Popup buttons={['Ok']} func={(i)=>{}} ref={pop} />
    <Popup buttons={['Cancel']} func={(i)=>{}} ref={search} >
      <Input placeholder='Enter phrase to search'  onChangeText={(v)=>setTosearch(v)}  />
      <View style={{flexDirection:'row', flexWrap:'wrap'}} >
        <Button onPress={()=>setSearched(book_search(tosearch))} title='Chinese Sutra Name' buttonStyle={styles.search_button} />
        <Button onPress= { text_search } title='Chinese Sutra Text' buttonStyle={styles.search_button} loading={loading} />
        { is_sutta_text() && <Button onPress={()=>{high.current.find(tosearch); search.current.hide(); }} title='Search within current text' buttonStyle={styles.search_button} /> }
      </View>
      <View style={{height:min_max_height([0.3, 200], [0.5], 0), width:min_max_width([0.5, 200], [0.8], 0.5)}} >
        <ScrollView >
        { searched.map((a)=> <Button title={a} buttonStyle={{backgroundColor:'#fff'}} titleStyle={{color:'#000'}} onPress={()=>{ navigation.replace('Home', to_option(a)) } } />)}
        </ScrollView>
      </View>
    </Popup>
    { is_sutta_text() 
    && <Highlighter style={{position:'absolute', bottom:20, right:20, width:30}} modefunc={()=>{setMode(!mode); high.current.selection_reset();}} mode={mode} 
        underlinefunc={(color)=>high.current.underline(color, scrollPosition, data.length)} />
    }
    </SafeAreaView>
  );

}

const styles = StyleSheet.create
( { font_button: 
    { alignItems: 'center'
    , justifyContent: 'center'
    , backgroundColor: Color.ORANGE[900]
    , width: 50
    , height: 40
    , borderRadius: 15
    , borderWidth: 1
    }
  , flags:
    { width: 50
    , heigth: 40
    , borderRadius: 15
    , borderWidth: 1
    , alignItems:'center'
    , justifyContent:'center'
    }
  , search_button:
    { backgroundColor: Color.ORANGE[900]
    , marginHorizontal: 3
    }
  }
);

const flag_image = 
[ require('./assets/en.png')
, require('./assets/de.png')
, require('./assets/es.png')
, require('./assets/fr.png')
, require('./assets/fi.png')
, require('./assets/my.png')
, require('./assets/hi.png')
, require('./assets/gu.png')
, require('./assets/ca.png')
, require('./assets/id.png')
, require('./assets/cs.png')
, require('./assets/jp.png')
, require('./assets/it.png')
, require('./assets/pl.png')
, require('./assets/lt.png')
, require('./assets/ru.png')
, require('./assets/pt.png')
, require('./assets/si.png')
, require('./assets/sr.png')
, require('./assets/vi.png')

, require('./assets/zh.png')
, require('./assets/no.png')
, require('./assets/ta.png')
, require('./assets/th.png')
, require('./assets/ko.png')
, require('./assets/bn.png')
, require('./assets/hu.png')
, require('./assets/sl.png')
]