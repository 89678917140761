import Modal from 'modal-react-native-web';
import  Color from 'react-native-material-color';
import React, { Component } from 'react'
import { View, StyleSheet, Platform, KeyboardAvoidingView } from 'react-native'
import { Overlay, Text, Button } from 'react-native-elements';

export class Popup extends Component 
{ //<Popup text='wil you ' buttons={['no', 'yes']} ref='pop' ><child></Popup>
  protected state={show: false, text: ''};
  run(txt?, func?) {
    this.setState({text: (txt === undefined ? this.props.text : txt)})
    this.setState({show: true}, func)
  }
  private func = null
  set_func(f) {
    this.func = f
  }
  on_click(i:number) {
    this.setState({show: false})
    if(this.func == null) this.props.func(i);
    else this.func(i)
  }
  hide() {
    this.setState({show: false})
  }
  render() {
    return (
      <Overlay ModalComponent={ Platform.OS == 'web' ? Modal : undefined} 
        isVisible={this.state.show} style={styles.container}>
        <KeyboardAvoidingView behavior='padding' style={[styles.container, this.props.style]} >
          <Text style={styles.text}>{this.state.text}</Text>
          {this.props.children}
          <View style={styles.foot}>
            {this.props.buttons.map((s:string, i)=>{ 
              return <Button raised type='outline' style={styles.button} titleStyle={styles.title}
                key={i} title={s} buttonStyle={styles.outline} onPress={() => { this.on_click(i) }} />
            })}
          </View>
        </KeyboardAvoidingView>
      </Overlay>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start',
    //backgroundColor: '#0f0',
    //alignItems: '',
  },
  foot: {
    marginTop: 5,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  } 
  , button: 
    { padding:1
    , marginHorizontal: 5
    }
  , outline: 
    { borderColor: Color.DEEPPRUPLE[700]
    }
  , title: { color: Color.DEEPPRUPLE[700]}
  , text: 
    { fontSize: 18
    , padding: 10
    }
  }
);
