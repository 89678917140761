import { Dimensions, Linking, ScrollView, StyleSheet, View } from "react-native";
import { Image } from "react-native-elements";
import Markdown from "react-native-markdown-display";
import { llog } from "./util";

const cbeta_copyright = `
# CBETA Chinese Electronic Tripiṭaka Collection
---
## Copyright

The "Chinese Buddhist Electronic Text Association" (CBETA) has been formally established on Feb. 15, 1998 with support from the Yinshun Foundation of North-America (http://www.yinshun.org) and Chung-Hwa Institute of Buddhist Studies (http://www.chibs.edu.tw) to create, maintain and distribute free of charge an electronic version of the Chinese Buddhist Triptitaka. Since February 2001, the Seeland Education Foundation administered the assistance to this project.

The CBETA Chinese Electronic Tripitaka is based on:

- Taishō Tripiṭaka 「大正新脩大藏經」( © Daizo Shuppansha 大藏出版株式會社) Vols. 1-55 & 85
- Shinsan Zokuzōkyō (Xuzangjing) 「卍新纂續藏經」(© Kokusho Kankokai 株式會社國書刊行會 ) Vols. 1-90
- Selected Chinese Buddhist Canon from other editions
  - Jiaxing Canon 「嘉興大藏經」(新文豐出版公司) Vols. 1-40
  - Fangshan shijing 「房山石經」, Jin Edition of the Canon「趙城金藏」, Northern Yongle Edition of the Canon 「永樂北藏」, and others.
- Selections from the Taipei National Central Library Buddhist Rare Book Collection 「國家圖書館善本佛典」 ( © 國家圖書館 )
- Chinese Translation of the Pāli Canon (Yuan Heng Temple edition) 「漢譯南傳大藏經」 ( © 元亨寺 ) Vols. 1-70
- Selected Buddhist materials not contained in the Tripiṭaka
  - Buddhist Texts not contained in the Tripiṭaka 「藏外佛教文獻」 (© Fang Guangchang) Vols. 1-9
  - Passages concerning Buddhism from the Official Histories 「正史佛教資料類編」 (© Du Doucheng )
  - Selections of Buddhist Stone Rubbings from the Northern Dynasties 「北朝佛教石刻拓片百品」 (© Institute of History and Philology, Academia Sinica)
  - Supplement to the Dazangjing 「大藏經補編」(© Lan Ji-fu) Vols. 1-36
  - Zhongguo Fosi Shizhi Huikan 「中國佛寺史志彙刊」 (杜潔祥主編)
  - Zhongguo fosizhi congkan 「中國佛寺志叢刊」 (張智等編輯)

The right to input and distribute this database has been officially granted by the copyright holders Daizo Shuppansha, Kokusho Kankokai, Taipei National Central Library, Yuan Heng Temple, Mr. Du Doucheng, Mr. Fang Guangchang and Institute of History and Philology, Academia Sinica. We would like to express our gratitude for this.

Please observe the following while using this database:

1. Permission to use this database is limited to individuals and organizations for non profit use. Any commercial use requires the prior written permission of both this Association and the copyright holders.
2. Permission to distribute or otherwise use any material from this database on computer networks, CD-ROM or any other media is granted on condition that its content is not altered and the copyright notice as well as version information is kept intact and visible to the user.
3. If you encounter any error in this material, please report it to this Association.
4. This Association will not be responsible for any harm or damage that might result in the usage of this database.
5. All rights for this database are reserved.

**Chinese Buddhist Electronic Text Association**

All Rights Reserved. Priceless, not to be sold. No Commercial Use Allowed.
`
const suttacentral_copyright = `
# Licensing
---

The Buddhist texts, whether in original languages or translation, are regarded by the Buddhist traditions as “the Buddha’s words”. They are a spiritual heritage of humanity, not belonging to any individual. At SuttaCentral we continue this tradition by making the texts available to everyone free of charge. Our work would not be possible if it were not for the freely given contributions of countless people, and we hope to repay this kindness in some small way by contributing our work for free.

Our source files are freely available on SuttaCentral’s Github repository. SuttaCentral is proud to be part of the open source community.

Material on SuttaCentral falls in three distinct categories in relation to copyright, which are detailed below. If you wish to assert copyright over any material on SuttaCentral, or wish to discuss copyright on SuttaCentral, please do not hesitate to contact us on our forum.

## 1. Original material created by SuttaCentral

All original material created by SuttaCentral is dedicated to the Public Domain by means of Creative Commons Zero (CC0 1.0 Universal).

This includes all text, design, software, and images created by SuttaCentral or persons working for SuttaCentral, on the domain suttacentral.net, or any domains or subdomains owned or managed by SuttaCentral, unless otherwise specified.

You are invited to copy, alter, redistribute, present, perform, convey, or make use of any or all of this material in any way you wish.

If you make use of any of our material, even though you are under no legal obligations, we respectfully request that you:

- Provide a notice of attribution.
- Maintain the spirit of the Buddhist tradition.

Please let us know if you would like any assistance in making use of our materials.
## 2. Material created by others and made available for SuttaCentral

For certain of the material on SuttaCentral the copyright has been asserted by third parties. This includes most of the translated texts. In such cases the terms of the copyright are specified by the copyright asserter, who is usually the translator or original publisher. Such material is used in accord with the license, or by permission. The relevant copyright notices as specified by the asserter of copyright are included with the material.

In addition, please note licenses for the following assets used by SuttaCentral. We gratefully acknowledge the creators for their work and generosity.

- We use several icons from the Noun Project, kindly released via Creative Commons Attribution (CC BY 3.0 US) .
  - “Difficulty” icons created by Alena Artemova.
  - “Language” ISO icons by Krzysiek Woźniak based on a design by Jonathan Coutiño
  - “Disconnected cloud icon” by Bernar Novalyi.
-  The paintings on the Home page are by the Australian artist Kim Hoa Tram, and are used by kind permission of the artist and the National Gallery of Victoria. These and other works by Kim Hoa Tram may be seen at the National Gallery of Victoria website.

## 3. Public domain material

The original texts of Buddhism in Pali, Chinese, Sanskrit, Tibetan, and other languages are in the public domain. Such material does not fall within the scope of copyright.

In addition, the reference data, including information on parallels, is not an “original creation” and as such does not fall within the scope of copyright.
`
const deepl_credit = `
## Machine translations from the CBETA corpus by DeepL
---
### License

These translations are published under the Attribution 4.0 International  (CC BY 4.0) (https://creativecommons.org/licenses/by/4.0/). The translations were created by: 

DeepL SE, Maarweg 165, 50825 Cologne, Germany 

### About these translations

This text was translated by DeepL using an experimental model that was trained as an internal, non-commercial research project. Given the sparsity of the training material and the diverse nature of the Taisho and Shinsan corpus, the model might not always meet DeepL's usual quality standards. Please take extreme caution when interpreting the results.
We did not translate sentences with more than 400 characters as the resulting translations wouldn't be reliable at all.  

### How to publish 
You are free to publish these translations on your website. Please show a disclaimer (or a link to a disclaimer) with the translations so people can put the results into context. Please use the following disclaimer: 

This text was translated by DeepL using an experimental model that was trained as an internal, non-commercial research project. Given the sparsity of the training material and the diverse nature of the Taisho and Shinsan corpus, the model might not always meet DeepL's usual quality standards. Please take extreme caution when interpreting the results.

### Feedback

Feedback for the translations would be greatly appreciated. If possible, provide feedback in the form of sentence-aligned translations that we can use to improve the model.   
`

const zeta_api = `
## App source
ZetaSoft co.ltd made this app based on data from 3 sources mentioned above.  This app can be divided into 2 parts. 

- One is **Cbeta**'s Chinese original text complemented by Deepl's AI translation into English.  \
Although **Deepl** is very powerful and can help readers comprehend original text, \
**the accurracy of these machine translated English texts from Chinese is not yet adequate for religious or academic use.** \
We got 大正新脩大藏經 and 卍新纂續藏經 translations directly from github. Other translations are obtained by inserting texts into Deepl Web site.\
So it is possible that former transaltions are more accurate than the latter.
- The other is early Buddhism texts and its translations into many languages generated or collected by **SuttaCentral**. \
These translations are made by specialists and reliable. But, they didn't yet make all the translations available.\
So only a part of translations can be seen in this app. At least English translation is available.
We followed [sutra name abbreviations](https://suttacentral.net/abbreviations?lang=en) in Sutta Central.


## ZetaSoft API

http://park.zeta2374.com/cbeta/:uuid

http://park.zeta2374.com/deepl/:uuid

http://park.zeta2374.com/nikaya/:file

## Report
https://www.zeta2374.com/Page/20/2 or email zeta@zeta2374.com

` 
+ '```' 
+ `
{json:'hello'}
` 
+ '```\n' ;
const api_md = `
## CBETA text api(https://api.zeta2374.com/cbeta/:uuid)

We simplified api based on CBETA api.

Top level uuid is 'collections'.
http://api.zeta2374.com:5500/cbeta/collections
will get result like this.

\`\`\`
{'_id': 'collections',
 '_rev': '1-332ab09509c45a620aebe429731c2eb4',
 'data': [{'name': 'A 金藏',
   'resourceCount': 9,
   'uuid': 'c64eee93-3c77-4b26-8bf2-602dd1352fad'},
  {'name': 'B 大藏經補編',
   'resourceCount': 201,
   'uuid': 'b191cc21-c191-48e6-b36f-ba4a76517cb3'},
  {'name': 'C 中華藏',
   'resourceCount': 10,
   'uuid': '3ebdca04-a4b3-4e23-ada7-cf4bd8a72ede'},
  {'name': 'D 國家圖書館善本佛典',
   'resourceCount': 64,
   'uuid': '1c103730-20b3-4a78-840b-a9f452e666a0'},
	......
\`\`\`

Now you can go one level deeper with uuid in received data.
http://api.zeta2374.com:5500/cbeta/c64eee93-3c77-4b26-8bf2-602dd1352fad
will get result like this.

\`\`\`
{'_id': 'c64eee93-3c77-4b26-8bf2-602dd1352fad',
 '_rev': '1-aa5e3e1136beb4cbed687763dcc6fe96',
 'data': [{'name': 'A1057 新譯大方廣佛華嚴經音義',
   'uuid': 'db86ecd1-1f90-48a4-96f0-8f532d770a0b'},
  {'name': 'A1267 大唐開元釋教廣品歷章', 'uuid': 'e486e1dd-0385-4318-8bf2-429ea87a2eab'},
  {'name': 'A1490 天聖釋教總錄', 'uuid': '0122d671-42b0-4482-800e-848d3f9b3482'},
  {'name': 'A1493 大中祥符法寶錄', 'uuid': 'b6e69f54-c8ea-4e6c-a524-7a851232cb4c'},
  {'name': 'A1494 景祐新修法寶錄', 'uuid': '36f360a9-22aa-4fcd-87e1-66cb749a5a8d'},
  {'name': 'A1504 佛說大乘僧伽吒法義經', 'uuid': '13ef5dad-efbb-459d-b07f-c74a1cbfcb58'},
  {'name': 'A1505 佛說清淨毘奈耶最上大乘經',
   'uuid': '5692c8b6-0824-4340-beb5-10da7d4d2b1e'},
  {'name': 'A1548 因明論理門十四過類疏', 'uuid': '7e91eca4-a469-4d41-867e-680b1a831b3c'},
  {'name': 'A1561 瑜伽師地論義演', 'uuid': '5cc7d9e9-47ec-483f-bea9-72091fb6d192'}]}
\`\`\`
Now you can go another level deeper with gained uuids.
http://api.zeta2374.com:5500/cbeta/e486e1dd-0385-4318-8bf2-429ea87a2eab
will get result like this.

\`\`\`
{'_id': 'e486e1dd-0385-4318-8bf2-429ea87a2eab',
 '_rev': '1-b2d45766f129b59ebe5b1615d86c1466',
 'data': [{'contentUnitCount': 1,
   'parentUuid': None,
   'title': 'A1267 大唐開元釋教廣品歷章 第3卷',
   'uri': 'http://cbetaonline.dila.edu.tw/A1267_003',
   'uuid': 'ffd7ac8c-deed-4a42-bf37-9cd4616d5d6c'},
  {'contentUnitCount': 1,
   'parentUuid': None,
   'title': 'A1267 大唐開元釋教廣品歷章 第4卷',
   'uri': 'http://cbetaonline.dila.edu.tw/A1267_004',
   'uuid': 'ecce2cbb-17ed-4349-841e-7e7e32afb8df'},
....
\`\`\`
Now last step.

http://api.zeta2374.com:5500/cbeta/ffd7ac8c-deed-4a42-bf37-9cd4616d5d6c
will get the sutra text.

\`\`\`
{'_id': 'ffd7ac8c-deed-4a42-bf37-9cd4616d5d6c',
 '_rev': '1-84f60f5d58498d79ca2e8086dd325d76',
 'data': [{'title': 'A1267 大唐開元釋教廣品歷章 第3卷',
   'uuid': 'cc17b7da-f33e-4da5-b61f-1cf79deb3748'},
'contents': '#----------------------------------------------------------------------\n
#【經文資訊】趙城藏第 97 冊 No. 1267 大唐開元釋教廣品歷章(第3卷-第4卷)\n
#【版本記錄】發行日期：2022-10，最後更新：2022-10-12\n
#.....
\`\`\`
Beware that data is an array and the uuid of this contents is of no use.

If you go 4 levels down(collections -> book -> volume -> text), you will get the sutra text.
If you are tired of this procedure, you can download [index file](http://gui.zeta2374.com/html_data/cbeta.json).
This file contains **all the json data excluding the last level**. We recommend using this index file.

## CBETA translation api(https://api.zeta2374.com/deepl/:uuid)


uuid of this request should be same as the **uuid of last level CBETA text**.
https://api.zeta2374.com/deepl/c5eca8c1-721e-4ff8-98d0-0e34538277a2.
If the uuid is one of T 大正新脩大藏經 or X 卍新續藏, you will get result like this.

\`\`\`
{'_id': 'T01n0009_001',
 '_rev': '2-b54ec00bb01cadd4cd869f61001a0daf',
 'book': 'T0009',
 'uuid': 'c5eca8c1-721e-4ff8-98d0-0e34538277a2',
 'volume': 1,
 'data': [{'is_bad_quality': False,
   'source': 'T01n0009_001:0213c22_0',
   'source_sentence': '佛說人仙經',
   'translation': 'Foshuo Renxian jing'},
  {'is_bad_quality': False,
   'source': 'T01n0009_001:0213c24_0',
   'source_sentence': '西天譯經三藏朝奉大夫試光祿',
   'translation': 'Xitian Translation of the Sutra of the Tripiṭaka, Chaofeng Dafu, and Xiguanglu'},
  {'is_bad_quality': False,
   'source': 'T01n0009_001:0213c25_0',
   'source_sentence': '卿明教大師臣法賢奉\u3000詔譯',
   'translation': 'Translated by imperial order from the Mingjiao dashi ministers, Faxian'},
 .....
 ]
}
\`\`\`
## SuttaCentral text api(https://api.zeta2374.com:5500/nikaya/:filename)
You need the [index file](http://gui.zeta2374.com/html_data/nikaya.json) to use this api.
This json file is structured like this.

\`\`\`
sutta: {
  dn : ['dn1_root-pli-ms', 'dn2_root-pli-ms'....],
  mn : ['mn1_root-pli-ms', ......],
  sn : { sn1 : ['sn1.1_root-pli-ms', .....],
       sn2 : [...]
	}
},
vinaya: {
...
},
abhidhamma: {
...
}
\`\`\`
At the end of each extremities there is an array of file names. 

https://api.zeta2374.com:5500/nikaya/dn1_root-pli-ms
will get result like this.

\`\`\`
{'_id': 'dn1_root-pli-ms',
 '_rev': '1-c0ce71a191d86733a2bb95ed8a5fa2d4',
 'data': {'dn1:0.1': 'Dīgha Nikāya 1 ',
  'dn1:0.2': 'Brahmajālasutta ',
  'dn1:1.0': '1. Paribbājakakathā ',
  'dn1:1.1.1': 'Evaṁ me sutaṁ—',
  'dn1:1.1.2': 'ekaṁ samayaṁ bhagavā antarā ca rājagahaṁ antarā ca nāḷandaṁ 
...
  },
'translation': {'offset': 11300,
  'rows': [{'id': 'dn1_translation-de-sabbamitta',
    'key': 'dn1_translation-de-sabbamitta',
    'value': {'rev': '1-bc82f7787f7b495c97a6fcf633688885'}},
   {'id': 'dn1_translation-en-davis',
    'key': 'dn1_translation-en-davis',
    'value': {'rev': '1-78de8f4a92a1aba7d6b875600f6b09a4'}},
},
'html_translation': {'offset': 16377,
  'rows': [{'id': 'dn1_translation-bn',
    'key': 'dn1_translation-bn',
    'value': {'rev': '1-c9a24b2ee4994b21828002428644a079'}},
   {'id': 'dn1_translation-cs',
    'key': 'dn1_translation-cs',
    'value': {'rev': '1-54758ac9ce24354bf2fb21d0065ac4c1'}},
 }
\`\`\`
This is a pali text and available translation file names.
`
const api_new  = `
I take refuge in **Buddha**, the greatest teacher, perfectly enlightened one.
I take refuge in **Dharma**, the bringer of the ultimate freedom. 
I take refuge in **Sangha**, the protector of the Buddha's teachings.

# App source
ZetaSoft co.ltd made this app based on data from 3 sources mentioned above.  This app can be divided into 2 parts. 

- One is **Cbeta**'s Chinese original text complemented by Deepl's AI translation into English.  
Although **Deepl** is very powerful and can help readers comprehend original text, 
**the accurracy of these machine translated English texts from Chinese is not yet adequate for religious or academic use.** 
We got 大正新脩大藏經 and 卍新纂續藏經 translations directly from github.
- The other is early Buddhism texts and its translations into many languages generated or collected by **SuttaCentral**. 
These translations are made by specialists and reliable. 
We followed [sutra name abbreviations](https://suttacentral.net/abbreviations?lang=en) in Sutta Central.

# ZetaSoft APIs

We are exposing apis for the pubic good.
There are 4 apis.

- GET https://sutta.zeta2374.com:5005/cbeta/:uuid
- GET https://sutta.zeta2374.com:5005/search/:text
- GET https://sutta.zeta2374.com:5005/deepl/:uuid
- GET https://sutta.zeta2374.com:5005/nikaya/:filename

First two are simplified version of cbeta apis.
Use third one to get english translations of 大正新脩大藏經 and 卍新纂續藏經.
Use last one to get Sutta Central texts including translations and original texts.
We have limited server resources, **please do not abuse these apis.**

## /cbeta/:uuid

Top level uuid is 'collections'.
/cbeta/collections
will get result like this.

\`\`\`
{'_id': 'collections',
 '_rev': '1-332ab09509c45a620aebe429731c2eb4',
 'data': [{'name': 'A 金藏',
   'resourceCount': 9,
   'uuid': 'c64eee93-3c77-4b26-8bf2-602dd1352fad'},
  {'name': 'B 大藏經補編',
   'resourceCount': 201,
   'uuid': 'b191cc21-c191-48e6-b36f-ba4a76517cb3'},
  {'name': 'C 中華藏',
   'resourceCount': 10,
   'uuid': '3ebdca04-a4b3-4e23-ada7-cf4bd8a72ede'},
  {'name': 'D 國家圖書館善本佛典',
   'resourceCount': 64,
   'uuid': '1c103730-20b3-4a78-840b-a9f452e666a0'},
	......
\`\`\`

Now you can go one level deeper with uuid in received data.
/cbeta/c64eee93-3c77-4b26-8bf2-602dd1352fad
will get result like this.

\`\`\`
{'_id': 'c64eee93-3c77-4b26-8bf2-602dd1352fad',
 '_rev': '1-aa5e3e1136beb4cbed687763dcc6fe96',
 'data': [{'name': 'A1057 新譯大方廣佛華嚴經音義',
   'uuid': 'db86ecd1-1f90-48a4-96f0-8f532d770a0b'},
  {'name': 'A1267 大唐開元釋教廣品歷章', 'uuid': 'e486e1dd-0385-4318-8bf2-429ea87a2eab'},
  {'name': 'A1490 天聖釋教總錄', 'uuid': '0122d671-42b0-4482-800e-848d3f9b3482'},
  {'name': 'A1493 大中祥符法寶錄', 'uuid': 'b6e69f54-c8ea-4e6c-a524-7a851232cb4c'},
  {'name': 'A1494 景祐新修法寶錄', 'uuid': '36f360a9-22aa-4fcd-87e1-66cb749a5a8d'},
  {'name': 'A1504 佛說大乘僧伽吒法義經', 'uuid': '13ef5dad-efbb-459d-b07f-c74a1cbfcb58'},
  {'name': 'A1505 佛說清淨毘奈耶最上大乘經',
   'uuid': '5692c8b6-0824-4340-beb5-10da7d4d2b1e'},
  {'name': 'A1548 因明論理門十四過類疏', 'uuid': '7e91eca4-a469-4d41-867e-680b1a831b3c'},
  {'name': 'A1561 瑜伽師地論義演', 'uuid': '5cc7d9e9-47ec-483f-bea9-72091fb6d192'}]}
\`\`\`
Now you can go another level deeper with gained uuids.
/cbeta/e486e1dd-0385-4318-8bf2-429ea87a2eab
will get result like this.

\`\`\`
{'_id': 'e486e1dd-0385-4318-8bf2-429ea87a2eab',
 '_rev': '1-b2d45766f129b59ebe5b1615d86c1466',
 'data': [{'contentUnitCount': 1,
   'parentUuid': None,
   'title': 'A1267 大唐開元釋教廣品歷章 第3卷',
   'uri': 'http://cbetaonline.dila.edu.tw/A1267_003',
   'uuid': 'ffd7ac8c-deed-4a42-bf37-9cd4616d5d6c'},
  {'contentUnitCount': 1,
   'parentUuid': None,
   'title': 'A1267 大唐開元釋教廣品歷章 第4卷',
   'uri': 'http://cbetaonline.dila.edu.tw/A1267_004',
   'uuid': 'ecce2cbb-17ed-4349-841e-7e7e32afb8df'},
....
\`\`\`
Now last step.

/cbeta/ffd7ac8c-deed-4a42-bf37-9cd4616d5d6c
will get the sutra text.

\`\`\`
{'_id': 'ffd7ac8c-deed-4a42-bf37-9cd4616d5d6c',
 '_rev': '1-84f60f5d58498d79ca2e8086dd325d76',
 'data': [{'title': 'A1267 大唐開元釋教廣品歷章 第3卷',
   'uuid': 'cc17b7da-f33e-4da5-b61f-1cf79deb3748'},
'contents': '#----------------------------------------------------------------------\n
#【經文資訊】趙城藏第 97 冊 No. 1267 大唐開元釋教廣品歷章(第3卷-第4卷)\n
#【版本記錄】發行日期：2022-10，最後更新：2022-10-12\n
#.....
\`\`\`
Beware that data is an array and the uuid of this contents is of no use.

If you go 4 levels down(collections -> book -> volume -> text), you will get the sutra text.
If you are tired of this procedure, you can download [index file](http://gui.zeta2374.com/html_data/cbeta.json).
This file contains all the json data excluding the last level. We recommend using this index file.

## /search/:text

The text should not be escaped. Just use plain chinese letters((ex)心解脫). %~~~ will cause error.

/search/法鼓 will get result like this.

\`\`\`
{
  "num_found": 1,
  "total_term_hits": 1,
  "results": [
    {
      "id": 12298,
      "juan": 1,
      "category": "法華部類",
      "canon": "T",
      "vol": "T09",
      "work": "T0270",
      "term_hits": 31,
      "title": "大法鼓經",
      "file": "T09n0270",
      "byline": "劉宋 求那跋陀羅譯",
      "creators": "求那跋陀羅",
      "creators_with_id": "求那跋陀羅(A000527)",
      "time_dynasty": "劉宋",
      "time_from": 420,
      "time_to": 479,
      "juan_list": "1,2"
    }
  ]
}
\`\`\`

## /deepl/:uuid

uuid of this request should be same as the **uuid of last level CBETA text**.
/deepl/c5eca8c1-721e-4ff8-98d0-0e34538277a2.
If the uuid is one of T 大正新脩大藏經 or X 卍新續藏, you will get result like this.

\`\`\`
{'_id': 'T01n0009_001',
 '_rev': '2-b54ec00bb01cadd4cd869f61001a0daf',
 'book': 'T0009',
 'uuid': 'c5eca8c1-721e-4ff8-98d0-0e34538277a2',
 'volume': 1,
 'data': [{'is_bad_quality': False,
   'source': 'T01n0009_001:0213c22_0',
   'source_sentence': '佛說人仙經',
   'translation': 'Foshuo Renxian jing'},
  {'is_bad_quality': False,
   'source': 'T01n0009_001:0213c24_0',
   'source_sentence': '西天譯經三藏朝奉大夫試光祿',
   'translation': 'Xitian Translation of the Sutra of the Tripiṭaka, Chaofeng Dafu, and Xiguanglu'},
  {'is_bad_quality': False,
   'source': 'T01n0009_001:0213c25_0',
   'source_sentence': '卿明教大師臣法賢奉\u3000詔譯',
   'translation': 'Translated by imperial order from the Mingjiao dashi ministers, Faxian'},
 .....
 ]
}
\`\`\`

## /nikaya/:filename

You need the [index file](http://gui.zeta2374.com/html_data/nikaya.json) to use this api.
This json file is structured like this.

\`\`\`
sutta: {
  dn : ['dn1_root-pli-ms', 'dn2_root-pli-ms'....],
  mn : ['mn1_root-pli-ms', ......],
  sn : { sn1 : ['sn1.1_root-pli-ms', .....],
       sn2 : [...]
	}
},
vinaya: {
...
},
abhidhamma: {
...
}
\`\`\`
At the end of each extremities there is an array of file names. 

/nikaya/dn1_root-pli-ms
will get result like this.

\`\`\`
{'_id': 'dn1_root-pli-ms',
 '_rev': '1-c0ce71a191d86733a2bb95ed8a5fa2d4',
 'data': {'dn1:0.1': 'Dīgha Nikāya 1 ',
  'dn1:0.2': 'Brahmajālasutta ',
  'dn1:1.0': '1. Paribbājakakathā ',
  'dn1:1.1.1': 'Evaṁ me sutaṁ—',
  'dn1:1.1.2': 'ekaṁ samayaṁ bhagavā antarā ca rājagahaṁ antarā ca nāḷandaṁ 
...
  },
'translation': {'offset': 11300,
  'rows': [{'id': 'dn1_translation-de-sabbamitta',
    'key': 'dn1_translation-de-sabbamitta',
    'value': {'rev': '1-bc82f7787f7b495c97a6fcf633688885'}},
   {'id': 'dn1_translation-en-davis',
    'key': 'dn1_translation-en-davis',
    'value': {'rev': '1-78de8f4a92a1aba7d6b875600f6b09a4'}},
},
'html_translation': {'offset': 16377,
  'rows': [{'id': 'dn1_translation-bn',
    'key': 'dn1_translation-bn',
    'value': {'rev': '1-c9a24b2ee4994b21828002428644a079'}},
   {'id': 'dn1_translation-cs',
    'key': 'dn1_translation-cs',
    'value': {'rev': '1-54758ac9ce24354bf2fb21d0065ac4c1'}},
 }
\`\`\`
This is a pali text and available translation file names.

Any of html_translation.rows or translation.rows array id will do.
/nikaya/dn1_translation-bn or /nikaya/dn1_translation-en-davis will get results. 
'html_translation' will get you a html file whereas 'translation' will get you a json file structured same as pali text data.

# Report

In case of any trouble, please report at https://www.zeta2374.com/Page/124/1

or email at zeta@zeta2374.com.
`

export function Credit({route, navigation})
{
  let dim = Dimensions.get('window').width
  return (
    <ScrollView>
      <Image source={require('./assets/cbeta.jpg')} style={{ width: '100%', height: dim * 191 / 984 }} resizeMode='contain' onPress={()=>Linking.openURL('https://www.cbeta.org')} />
      <View style={styles.markdown} >
        <Markdown>
          {cbeta_copyright}
        </Markdown>
      </View>
      <Image source={require('./assets/suttacentral.png')} style={{ width: '100%', height: dim * 168 / 973 }} resizeMode='contain' onPress={()=>Linking.openURL('https://suttacentral.net')} />
      <View style={styles.markdown} >
        <Markdown>
          {suttacentral_copyright}
        </Markdown>
      </View>
      <Image source={require('./assets/deepl.png')} style={{ width: '100%', height: dim * 214 / 2000 }} onPress={()=>Linking.openURL('https://www.deepl.com')} />
      <View style={styles.markdown} >
        <Markdown>
          {deepl_credit}
        </Markdown>
      </View>
      <Image source={require('./assets/zeta2.jpg')} style={{ width: '100%', height: dim * 270 / 2054 }} onPress={()=>Linking.openURL('https://www.zeta2374.com')} />
      <View style={styles.markdown} >
        <Markdown>
          {api_new}
        </Markdown>
      </View>
      <Image source={require('./assets/paypal.png')} style={{width:'100%', height: dim * 148 / 1300}} onPress={()=>{navigation.navigate('Donate')}} />
    </ScrollView>
  )

}

const styles = StyleSheet.create
( { markdown: { padding: 15}})