import AsyncStorage from "@react-native-async-storage/async-storage"
import { Dimensions } from "react-native"

export async function fetch2(jv) {
	let site= 'https://sutta.zeta2374.com:5005/'
  let headers = {'Content-Type':'application/json; charset=utf-8'}
  //headers['Access-Control-Allow-Origin'] = '*'
  let r = await AsyncStorage.getItem('data_' + jv);
  if(r != null) return JSON.parse(r)
  return fetch
  ( site + jv
  , { method: 'GET'
    , headers: headers
    , mode: 'cors'
    }
  )
  . then((r)=>{return r.json()})
  . catch((e)=>{ return e; })
}

export function cbeta_search(jv) {// Server is not configured to bypass CORS, so this had to be rerouted to my server
  return fetch
  ( 'http://cbdata.dila.edu.tw/v1.2/sphinx?q=' + jv
  , { method: 'GET'
    , mode: 'cors'
    , headers: {'Content-Type': 'application/json; charset=utf-8', 'Access-Control-Allow-Origin': '*'}
    }
  )
  . then((r)=>r.json())
  . catch((e)=> e)
}

export function llog(txt, x, level?) {
  if(level === undefined) level = 0;
  if('log_level' in global) if(global.log_level.indexOf(level) != -1 || level < 0) console.log(txt, ' : ', x)
}

function max_width(s:Number[]):Number {
  let r = 0;
  for(let i of s) {
    if(i > 0 && i < 1) r = Math.max(r, Dimensions.get('window').width * i);
    else if(i < 0) r = Math.max(r, Dimensions.get('window').width + i * 2);
    else r = Math.max(r, i);
  }
  return r;
}

function min_width(s:Number[]):Number {
  let r = 9999999;
  for(let i of s) {
    if(i > 0 && i < 1) r = Math.min(r, Dimensions.get('window').width * i);
    else if(i < 0) r = Math.min(r, Dimensions.get('window').width + i * 2);
    else r = Math.min(r, i);
  }
  return r;
}

function max_height(s:Number[]):Number {
  let r = 0;
  for(let i of s) {
    if(i > 0 && i < 1) r = Math.max(r, Dimensions.get('window').height * i);
    else if(i < 0) r = Math.max(r, Dimensions.get('window').height + i * 2);
    else r = Math.max(r, i);
  }
  return r;
}

function min_height(s:Number[]):Number {
  let r = 9999999;
  for(let i of s) {
    if(i > 0 && i < 1) r = Math.min(r, Dimensions.get('window').height * i);
    else if(i < 0) r = Math.min(r, Dimensions.get('window').height + i * 2);
    else r = Math.min(r, i);
  }
  return r;
}

export function min_max_width(min:Number[], max:Number[], weight?:number)
{//minus : margin horizontal, float : % of width, + : px
  let a = max_width(min);
  let b = min_width(max);
  if(weight === undefined) weight = 0.5;
  return a + (b - a) * weight;
}

export function min_max_height(min:Number[], max:Number[], weight?:number) {
  let a = max_height(min);
  let b = min_height(max);
  if(weight === undefined) weight = 0.5;
  return a + (b - a) * weight;
}

export function apply_width(apply, break_point:number[]) {
  const w = Dimensions.get('window').width;
  break_point.splice(0, 0, 0);
  break_point.push(999999);
  for(let i=0; i<break_point.length -1; i++) 
    if(break_point[i] <= w  && w < break_point[i+1]) return apply[i];
}

export function apply_height(apply, break_point:number[]) {
  const h = Dimensions.get('window').height;
  break_point.splice(0, 0, 0);
  break_point.push(999999);
  for(let i=0; i<break_point.length -1; i++) 
    if(break_point[i] <= h && h < break_point[i+1]) return apply[i];
}

export function select_device(mobile, tablet, web) {
  const win = Dimensions.get('window');
  let w = win.width;
  let h = win.height;
  if(Platform.OS == 'web') return web;
  return h / w > 1.6 ? mobile : tablet;
}


